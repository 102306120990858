<template>
  <div class="new-worker">
      <div class="new-worker-header">
          <h1>עובד חדש</h1>
      </div>
  </div>
</template>

<script>
export default {
    setup(){

        return{}
    }
}
</script>

<style scoped>
    .new-worker{
        margin:0 auto;
        width: 100%;
        max-width: 500px;
        height: 100%;
        color: #fff;
        background: #283046;
        border-radius: 10px;

    }
    .new-worker-header{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>