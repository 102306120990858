<template>
  <div v-if="!state" class="human-resources-main">
      <div class="human-resources-main-header">
          <h1>משאבי אנוש</h1>
      </div>
      <div class="human-resources-main-grid">
          <div @click="state='NewWorker'" class="human-resources-main-grid-app">
              <div class="human-resources-main-grid-app-header">
                  <p class="header-text">עובד חדש</p>
              </div>
              <div class="human-resources-main-grid-app-icon">
                <img src="https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/icons%2Fplus_375x375.png?alt=media&token=d6b5fb0b-0fdc-4ca7-ab30-a96e25be148e">
              </div>
          </div>
      </div>
  </div>
  <div v-if="state" class="human-resources-states">
      <component :is="state"/>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import NewWorker from './Components/NewWorker.vue'
export default {
    components:{NewWorker},
    setup(){
        const state = ref(null)
        return{state}
    }
}
</script>

<style scoped>
    .human-resources-main{
        padding: 5px 0;
        width: 100%;
        height: 100%;
        color: #fff;
    }
    .human-resources-main-header{
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #283046;
        border-radius: 10px;
    }
    .human-resources-main-grid{
        padding: 5px;
        width: 100%;
        height: calc(100% - 60px);
        display: grid;
        grid-template-columns:repeat(auto-fill,minmax(300px, 1fr));
        grid-auto-rows: 200px;
        grid-gap: 5px;
    }
    .human-resources-main-grid-app{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: #283046;
        border-radius: 10px;
        cursor: pointer;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    }
    .header-text{
        font-size: 30px;
    }
    .human-resources-main-grid-app-header{
        width: 100%;
        height: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .human-resources-main-grid-app-icon{
        width: 100%;
        height: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .human-resources-main-grid-app-icon img{
        max-height: 90%;
        max-width: 90%;
    }
    @media screen and (max-width: 600px) {
         .human-resources-main-grid{
             grid-template-columns:repeat(auto-fill,minmax(110px, 1fr));
             grid-auto-rows: 110px;
         }
        .human-resources-main-grid-app-header{
            height: 40%;
        }
        .human-resources-main-grid-app-icon{
            height: 60%;
        }
        .header-text{
            font-size: 18px;
        }
        
    }
    .human-resources-states{
        width: 100%;
        height: 100%;
        padding: 5px;
    }


</style>